<template>
  <div class="ai-video-list">
    <el-tabs v-model="state.activeName" class="menu-tabs" @tab-change="tabsChange">
      <!-- 我的视频 -->
      <el-tab-pane label="我的视频" name="videos">
        <div class="filter-menu">
          <h1 class="filter-menu-title">我的视频</h1>
          <el-popover placement="bottom" effect="dark" :show-arrow="false" trigger="click" popper-class="sort-wrap">
            <template #reference>
              <div class="sort">
                <i className="iconfont icon-shijian" />
                <span>时间排序</span>
                <img src="../assets/video/up_sort.svg">
                <i className="iconfont icon-xialajiantou" style="margin-left: auto; opacity: 0.5;" />
              </div>
            </template>
            <div class="sort-popover">
              <div class="sort-item">
                <i className="iconfont icon-shijian" />
                <span>时间顺序</span>
                <img src="../assets/video/up_sort.svg">
              </div>
              <div class="sort-item">
                <i className="iconfont icon-shijian" />
                <span>时间倒序</span>
                <img src="../assets/video/up_sort.svg" style="transform:scaleY(-1);">
              </div>
            </div>
          </el-popover>
          <div class="type-wrap">
            <span :class="`type-item ${state.videType === item.id ? 'active' : ''}`" v-for="item in state.videoTypeList"
              :key="item.id" @click="toogleVideoType(item.id)">{{ item.type_name }}</span>
          </div>
        </div>
        <ul class="video-list" v-infinite-scroll="getVideosList" :infinite-scroll-distance="400"
          v-if="state.videoList.length > 0">
          <li class="video-item" v-for="item in state.videoList" :key="item.id" @click="openVideo(item.id)">
            <el-image class="video-img" :src="item.video_img" loading="lazy" />
            <p class="video-title">{{ item.video_name }}</p>
            <p>
              <span>0 month</span>
              <span>0 words</span>
              <span>{{ formatTime(item.creationtime) }}</span>
            </p>
            <!-- <div class="action-icon">
              <img class="icon" src="../assets/video/play.svg">
              <img class="icon" src="../assets/video/share.svg">
              <img class="icon" src="../assets/video/delete.svg">
            </div> -->
          </li>
        </ul>
        <div class="empty" v-else>
          <img src="../assets/video/video_empty.png" alt="">
          <p>您还没有收藏任何视频</p>
          <ul>
            <li>1.安装 chat 扩展插件</li>
            <li>2.打开 xxxx 或 xxxx视频</li>
            <li>3.打开 chat 插件</li>
            <li>4.再播放器控制栏中单机五星图标</li>
          </ul>
          <el-button>安装 chat 扩展插件</el-button>
        </div>
        <!-- <el-pagination
          v-if="state.videoList.length > 0"
          v-model:current-page="state.videoCursor"
          :page-size="20"
          :small="true"
          :background="true"
          layout="prev, pager, next, jumper"
          :total="1000"
          @current-change="handleVideoCurPageChange"
        /> -->
      </el-tab-pane>
      <!-- 我的词汇 -->
      <el-tab-pane label="我的词汇" name="words">
        <div class="filter-menu">
          <el-popover placement="bottom" effect="dark" :show-arrow="false" trigger="click" popper-class="sort-wrap">
            <template #reference>
              <div class="sort">
                <i className="iconfont icon-shijian" />
                <span>时间排序</span>
                <img src="../assets/video/up_sort.svg">
                <i className="iconfont icon-xialajiantou" style="margin-left: auto; opacity: 0.5;" />
              </div>
            </template>
            <div class="sort-popover">
              <div class="sort-item">
                <i className="iconfont icon-shijian" />
                <span>时间顺序</span>
                <img src="../assets/video/up_sort.svg">
              </div>
              <div class="sort-item">
                <i className="iconfont icon-shijian" />
                <span>时间倒序</span>
                <img src="../assets/video/up_sort.svg" style="transform:scaleY(-1);">
              </div>
            </div>
          </el-popover>
          <div class="word-filter">
            <span :class="`tab ${state.wordType === 1 ? 'active' : ''}`" @click="state.wordType = 1">待学单词</span>
            <span :class="`tab ${state.wordType === 2 ? 'active' : ''}`" @click="state.wordType = 2">已掌握单词</span>
          </div>
        </div>
        <ul class="words-list">
          <li class="words-item" v-for="word in state.wordsList" :key="word.id">
            <span style="width: 200px;margin-right: 20px;">{{ word.name }}</span>
            <span style="width: 300px;margin-right: 20px;color: #999;"><i class="iconfont icon-langdudanci hover-light"
                style="margin-right: 10px;"></i>{{ word.phonetic }}</span>
            <span style="width: 80px;margin-right: 20px;color: #999;">{{ word.counts }}次</span>
            <span style="width: 264px;color: #ccc;">{{ word.paraphrase }}</span>
            <div class="actions">
              <i :className="`iconfont ${state.wordsIds.includes(word.id) ? 'icon-Frame-5' : 'icon-Frame-4'}`"
                :style="`font-size: 24px;color: ${state.wordsIds.includes(word.id) ? 'var(--primary)' : '#999'};`"
                @click="toggleSelectWords(word.id)" />
              <i className="iconfont icon-Vector-1"
                :style="`font-size: 18px;color: ${state.spreadWord === word.id ? '#fff' : '#999'};`"
                @click="openSpreadWord(word.id)" />
            </div>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { onMounted, reactive, watchEffect, defineEmits, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import api from '../api/video';

const props = defineProps(['isdark', 'ismenu']);

const router = useRouter();

const state = reactive({
  activeName: 'videos',
  videoCursor: 1,
  videoList: [],
  videoTypeList: [],
  videType: 0,
  wordsList: [],
  wordsIds: [], // 选中的词汇
  spreadWord: 0,
  wordType: 1
})

onMounted(() => {
  init();
})




const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
}

const tabsChange = (name) => {
  if (name === 'words') {
    getWordsList();
  } else {
    state.videoCursor = 1;
    state.videoList = [];
    getVideosList();
  }
}

const getVideosList = async (type) => {
  if ((!type && !state.videType) || state.videoCursor === 0) return;
  const res = await api.aiVideoList({
    type_id: type || state.videType,
    cursor: state.videoCursor,
    limit: 20
  })
  const { code, data } = res;
  if (code === 0) {
    state.videoList = [...state.videoList, ...data.msg];
    state.videoCursor = data.cursor;
  }
}
const getVideoTypeList = async () => {
  const res = await api.aiVideoType({ prompts_id: router.currentRoute.value.query.prompts_id })
  const { code, data } = res;
  if (code === 0) {
    state.videoTypeList = data.msg;
    if (state.videoTypeList.length > 0) toogleVideoType(state.videoTypeList[0].id);
  }
}

const toogleVideoType = (id) => {
  state.videType = id;
  getVideosList(id);
}

const getWordsList = async () => {
  // const res = await api.aiVideoWordsList({ video_id: 1});
  state.wordsList = [
    { id: 1, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
    { id: 2, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
    { id: 3, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
    { id: 4, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
    { id: 5, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
    { id: 6, name: 'morning', phonetic: '/ ɪnˈterəɡeɪt / / ɪnˈterəɡeɪt /', counts: 20, paraphrase: 'n.早上 早晨 上午 晨 早 朝 午前 旦 夙 初期' },
  ]
}

const toggleSelectWords = (id) => {
  let idx = state.wordsIds.findIndex(item => item === id);
  if (idx < 0) {
    state.wordsIds.push(id);
  } else {
    state.wordsIds.splice(idx, 1);
  }
}

const openSpreadWord = (id) => {
  if (state.spreadWord === id) return state.spreadWord = '';
  state.spreadWord = id;
}

const openVideo = (id) => {
  const href = router.resolve({
    path: '/ai-video/detail',
    query: {
      video_id: id
    }
  })
  window.open(href.href, '_blank');
}

const init = () => {
  if (state.activeName === 'videos') {
    getVideoTypeList();
  } else {
    getWordsList();
  }
}

</script>

<style lang="scss">
@import '../sass-style/common.scss';

.ai-video-list {
  position: relative;
  width: 100%;
  height: 100%;
  // padding: 20px;
  box-sizing: border-box;
  background-color: #1f222a;
  border-radius: 16px;

  .iconfont {
    cursor: pointer;

    &.hover-light:hover {
      color: var(--fff);
    }
  }

  .menu-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .el-tabs__header {
      margin: 0;
      display: none;
    }

    .el-tabs__item {
      color: #CCCCCC;
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 15px;
    }

    .el-tabs__nav-wrap {
      padding: 20px 40px 0;

      &::after {
        background-color: #323839;
      }
    }

    .el-tabs__content {
      padding: 14px 20px 10px 40px;
    }

    .el-tab-pane {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .filter-menu {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    z-index: 1;
    border-bottom: 1px solid #323839;
    padding-bottom: 17px;

    .filter-menu-title {
      /* 我的视频 */
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */
      color: #CCCCCC;
      mix-blend-mode: normal;
      margin-right: 40px;
    }

    .sort {
      display: flex;
      align-items: center;
      width: 130px;
      padding: 0 8px;
      line-height: 32px;
      border: 1px solid #999;
      box-sizing: border-box;
      border-radius: 8px;
      color: #999;
      font-size: 14px;
      font-weight: 500;

      span {
        margin-left: 8px;
        margin-right: 4px;
      }
    }

    .type-wrap {
      margin-left: 42px;

      .type-item {
        padding: 4px 12px;
        border-radius: 43px;
        border: 1px solid #ffffff10;
        color: #999;
        cursor: pointer;
        margin-right: 12px;

        &.active {
          color: var(--fff);
          border: 1px solid var(--primary);
        }
      }
    }
  }

  .video-list {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 40px;
    flex-wrap: wrap;
    overflow: auto;

    // overflow-y: scroll;
    .video-item {
      display: flex;
      flex-direction: column;
      list-style: none;
      width: 320px;
      height: 320px;
      box-sizing: border-box;
      color: #777;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      .video-img {
        width: 100%;
        background-color: var(--fff);
        margin-bottom: 20px;
        border-radius: 16px;
      }

      .video-title {
        color: #bbbbbb;
        font-size: 16px;
        height: 50px;
        line-height: 25px;
        @include text-overflow(2);

        +p {
          margin-top: auto;

          &>span:nth-child(2) {
            margin: 16px;
          }
        }
      }
    }

    .action-icon {
      display: flex;
      margin-top: auto;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 16px;
      }
    }
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .empty {
    width: 280px;
    color: var(--fff);
    font-size: 14px;
    text-align: left;
    margin: 150px auto;

    img {
      display: inline-block;
      margin: auto 0;
    }

    p {
      margin: 20px 0;
      font-size: 16px;
      font-weight: 500;
    }

    li {
      line-height: 30px;
    }

    button {
      width: 280px;
      line-height: 36px;
      background-color: var(--primary);
      color: var(--fff);
      border-radius: 40px;
      font-weight: 500;
      border: none;
      margin-top: 24px;
    }
  }

  .words-list {
    color: var(--fff);
    font-size: 14px;
    font-weight: 500;

    .words-item {
      display: flex;
      align-items: center;
      height: 55px;
      border-top: 1px solid #77777760;

      span {
        @include text-overflow(1);
      }

      &:last-of-type {
        border-bottom: 1px solid #77777760;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: auto;

        .icon {
          height: 18px;
          cursor: pointer;

          &:first-of-type:hover {
            content: url('../assets/video/lightcheck.svg');
          }

          &:first-of-type.checked {
            content: url('../assets/video/check_active.svg');
          }

          &:last-of-type:hover {
            content: url('../assets/video/lightfolder.svg');
          }
        }
      }
    }
  }

  .word-filter {
    color: var(--fff);
    background-color: var(--1f222a);
    padding: 3px;
    margin-left: 44px;
    border-radius: 8px;

    .tab {
      line-height: 32px;
      border-radius: 8px;
      padding: 4px 8px;
      cursor: pointer;

      &.active {
        background-color: var(--181a20);
      }

      +.tab {
        margin-left: 10px;
      }
    }
  }
}

.sort-wrap.el-popper.is-dark {
  border-radius: 8px;

  .sort-popover {
    color: #999;

    span {
      display: block;
    }

    .sort-item {
      display: flex;
      line-height: 38px;

      &:hover {
        background-color: #111215;
        color: var(--fff);
        border-radius: 8px;
      }

      span {
        margin-left: 8px;
        margin-right: 4px;
      }
    }
  }
}

@media (min-width: 2300px) {
  .ai-video-list .video-list {
    grid-template-columns: repeat(5, 1fr)
  }
}

@media (max-width: 1920px) {
  .ai-video-list .video-list {
    grid-template-columns: repeat(4, 1fr)
  }
}

@media (max-width: 1720px) {
  .ai-video-list .video-list {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 24px;
    padding: 32px;
    .video-item{
      width: 100%;
    }
  }
}
</style>