<template>
  <el-dropdown popper-class="mydrop"  @command="handleCommand" style="border:none !important;cursor: pointer;    align-items: center;" trigger="click">
    <img
        src="../../assets/arrowdown.svg"
        alt=""
        style="width: 20px; height: 20px"
    />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="chatrob" style="width:180px;height:40px"><img src="../../assets/leftMenuIcon/chatrob.svg" alt=""></el-dropdown-item>
        <el-dropdown-item command="englishrob" style="width:180px;height:40px"><img src="../../assets/leftMenuIcon/englishrob.svg" alt=""></el-dropdown-item>
       
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElMessage } from 'element-plus'
  export default {
	props:{
		file: {},
	},
	data() {
	  return {
		 
	  }
	},
 
	methods:{
    handleCommand(command){
      if(command == "englishrob"){
        this.$router.push({ name: "ai-video" });
      }else if(command == "chatrob"){
        this.$router.push({ name: "page" });
      }
      console.log(command)
    }
	}
  }
  </script>

 
<style scoped>
.el-dropdown-menu{
  background-color: #161719;
}
.el-dropdown-menu__item{
  color:white
}

 
:global(.mydrop .el-dropdown-menu__item:hover){
  background-color: #adadad!important;
}  
:global(.mydrop .el-dropdown-menu__item:focus){
  background-color: #adadad!important;
}  

:global(.mydrop.el-popper.is-light){
  border: 2px solid #adadad !important
}
:global(.mydrop.dropDownStyle.el-dropdown-menu) {
  background: #161719;
}
 
:global(.mydrop.el-popper.is-light .el-popper__arrow::before) {
    background: #161719;
    border: 2px solid #adadad !important
  }

  :global(.mydrop .el-dropdown-menu__item) {
    color: #00d8ff;
  }
 
/* .example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
} */
</style>
