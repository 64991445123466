<template>
  <div :class="`ai-video ${curPage}`">
    <Player v-if="showPlayer" />
    <Subtitle v-if="curPage === 'theater'" />
    <template v-else-if="curPage === 'read'">
      <i class="close-btn iconfont icon-Vector" @click="closeMode"></i>
      <ReadPage />
    </template>
    <template v-else>
      <PracticeModal v-if="practiceTip" @close="closePracticeTip" />
      <PracticePage />
    </template>
    <i
      v-if="curPage !== 'theater'"
      class="close-btn iconfont icon-Vector"
      @click="closeMode"
    ></i>
    <SliderPanel
      ref="sliderPanel"
      :word="sliderCurrentWord"
      @close="sliderShow = false"
    ></SliderPanel>
    <ControlBar :page="curPage" @changePage="changePage" />
  </div>
</template>
  
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ReadPage from "@/view/video-read";
import PracticePage from "@/view/video-practice";
import PracticeModal from "@/components/PracticeModal";
import ControlBar from "@/components/VideoControlBar";
import Subtitle from "@/components/Subtitle";
import SliderPanel from "@/components/SliderPanel";
import Player from "../components/VideoPlayer";
import api from "../api/video";
import { parseSubtitle, combineSubtitles } from "@/utils/tools";
import emitter from "@/utils/eventBus";
import EVENTS from "@/contants/events";

const router = useRouter();
const store = useStore();

const showPlayer = ref(false);
const video_id = router.currentRoute.value.query.video_id;
const lyric = ref([]);
const subtitlesType = computed(() => store.state.subtitles.subtitleType);
const defaultCurPage = computed(() => {
  const mode = router.currentRoute.value.params.pageMode;
  if (mode === "read" || mode === "practice") {
    return mode;
  } else {
    return "theater";
  }
});

const curPage = ref(defaultCurPage.value);
const practiceTip = ref(
  localStorage.getItem("practiceTip") === undefined ? true : false
);

onMounted(() => {
  if (video_id) {
    getVideoInfo();
  }
});

emitter.on("changeSubTitle", (data) => {
  subtitlesType.value = data;
});

// 练习模式：视频可见
emitter.on("practice-player-scale", (scale) => (showPlayer.value = scale));

const getVideoInfo = async () => {
  const res = await api.aiVideoInfo({ video_id });
  const { code, data } = res;
  if (code === 0 && data) {
    store.commit("videoPlayer/changeVideoInfo", data);
    showPlayer.value = true;
    const { cn_captions, en_captions } = data;

    const [cn_captions_arr, en_captions_arr] = await Promise.all([
      api.queryCaptions(cn_captions),
      api.queryCaptions(en_captions),
    ]);

    lyric.value = [
      cn_captions_arr ? parseSubtitle(cn_captions, cn_captions_arr, "cn") : [],
      en_captions_arr ? parseSubtitle(en_captions, en_captions_arr, "en") : [],
    ];

    store.commit("subtitles/updateSubtitle", {
      primarySubtitleUrl: cn_captions,
      secondarySubtitleUrl: en_captions,
      primarySubtitleList: lyric.value[1],
      secondarySubtitleList: lyric.value[0],
    });

    // 组合中英文字幕
    let combineSubtitle = combineSubtitles(lyric.value[1], lyric.value[0]);

    // 更新收藏句子的状态信息
    const collectList = await getSentenceList();
    collectList.map((item) => {
      const _listItem = combineSubtitle.filter(
        (cit) =>
          cit.startTime == item.start_time && cit.endTime == item.end_time
      )[0];
      if (_listItem) {
        Object.assign(_listItem, {
          id: item.id,
          is_like: item.is_like,
        });
      }
    });
    store.commit("subtitles/updateList", combineSubtitle);
  }
};

// 获取当前页面收藏的例句
const getSentenceList = async () => {
  const res = await api.sentenceList({ video_id, type: 1 });
  const { code, data } = res;
  if (code === 0 && data.length > 0) {
    store.commit("subtitles/updateSentence", data);
  }
  return code === 0 && data.length > 0 ? data : [];
};

const changePage = (page) => {
  curPage.value = page;
};

const closeMode = () => {
  curPage.value = "theater";
  store.commit(
    "videoPlayer/changeModeType",
    localStorage.getItem("userCheckedMode") || ModeType.all
  ); // 返回剧场模式时重新选择用户选择的循环模式
};

const closePracticeTip = () => {
  practiceTip.value = false;
  localStorage.setItem("practiceTip", false);
};

// 单词释义面板
const sliderPanel = ref(null);
const sliderCurrentWord = ref(null);
emitter.on(EVENTS.WORD_PANEL, (word) => {
  sliderPanel.value.changeVisible();
  sliderCurrentWord.value = word;
});
</script>
  
<style lang="scss" scoped>
@mixin ai-video-platform {
  [data-platform="mobile"] & {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  [data-platform="pc"] & {
    padding: 28px 20px 80px;
  }
}

p {
  margin: 0;
}
.ai-video {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @include ai-video-platform;
  box-sizing: border-box;
  background: var(--page-bg-color);
  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #666;
    font-size: 28px;
    cursor: pointer;
  }
  [data-platform="mobile"] & .close-btn{
    display: none;
  }
  .xgplayer {
    background: transparent;
  }
  [data-platform="mobile"] & .xgplayer {
    width: 100% !important;
    height: 200px !important;
    background: #000;
    flex:none;
  }
  &.theater {
    position: relative;
    background: var(--page-bg-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    [data-platform="pc"] & .xgplayer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  [data-platform="pc"] &.read,
  [data-platform="pc"] &.practice {
    .xgplayer {
      position: fixed;
      top: 20px;
      left: 20px;
      width: 24% !important;
      height: 24% !important;
    }
  }
}
</style>